<template>
    <div>
        <btn-date v-model="dateString"/><btn-hours v-model="hoursString"/>
    </div>
</template>

<script>
import moment from 'moment';
import btnDate from './btnDate.vue';
import btnHours from './btnHours.vue';

export default {
  props: {
    value: {
      type: Object,
      default: new Date(),
    },
  },
  data() {
    return {
      hoursString: moment.utc(this.value).local().format('HH:mm'),
      dateString: moment.utc(this.value).local().format('YYYY-MM-DD'),
    };
  },
  watch: {
    dateString() {
      this.refresh();
    },
    hoursString() {
      this.refresh();
    },
  },
  methods: {
    refresh() {
      const tps = `${this.dateString}T${this.hoursString}`;
      this.$emit('input', moment(tps).utc());
    },
  },
  components: {
    btnDate,
    btnHours,
  },
};
</script>
