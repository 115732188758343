<template>
  <v-card class="pa-2" outlined tile>
    <v-card-title>
      {{ counter.id }}
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        x-small
        color="primary"
        @click="dialogSetEsimTimeout = true"
        >Esim Timeout</v-btn
      >
      <v-btn
        class="mx-2"
        x-small
        color="primary"
        @click="dialogSetImuRecord = true"
        >Set IMU Records</v-btn
      >
      <v-btn
        class="mx-2"
        x-small
        color="primary"
        @click="dialogCanPeriod = true"
        >CAN Period</v-btn
      >
      <v-btn class="mx-2" x-small color="primary" @click="dialogSetPlane = true"
        >Set Ship Mode</v-btn
      >
      <v-btn
        class="ml-2"
        x-small
        color="primary"
        v-if="!!isSamsys"
        @click="dialogPrintLabel = true"
        >Print Label</v-btn
      >
      <v-dialog v-model="dialogSetImuRecord" max-width="500px">
        <v-card>
          <v-card-title> IMU Records ? </v-card-title>
          <v-card-actions>
            <v-btn color="success" @click="sendImuRecords(true)">Enable</v-btn>
            <v-btn color="error" @click="sendImuRecords(false)">Disable</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialogSetImuRecord = false"
              >cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPrintLabel" max-width="500px">
        <v-card>
          <v-card-title> Print Label </v-card-title>
          <v-card-actions>
            <v-btn color="primary" @click="printLabel(1)">1</v-btn>
            <v-btn color="primary" @click="printLabel(2)">2</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialogPrintLabel = false"
              >cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCanPeriod" max-width="500px">
        <v-card>
          <v-card-title> Set CAN Period </v-card-title>
          <v-card-text>
            <v-text-field
              type="number"
              v-model="valueCanMinUsPeriod"
              label="Millisecond Period"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              text
              @click="
                valueCanMinUsPeriod = 0;
                dialogCanPeriod = false;
              "
              >cancel</v-btn
            >
            <v-btn color="primary" @click="sendCanMinUsPeriod">Send</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSetEsimTimeout" max-width="500px">
        <v-card>
          <v-card-title> Set Esim Timout</v-card-title>
          <v-card-text>
            <v-text-field
              type="number"
              v-model="esimTimeout"
              label="Second Esim Timeout"
              min="0"
              max="1000"
              step="1"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              text
              @click="
                esimTimeout = 0;
                dialogSetEsimTimeout = false;
              "
              >cancel</v-btn
            >
            <v-btn color="primary" @click="sendEsimTimout">Send</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSetPlane" max-width="500px">
        <v-card>
          <v-card-title> Set Plane </v-card-title>
          <v-card-actions>
            <v-btn color="error" text @click="dialogSetPlane = false"
              >cancel</v-btn
            >
            <v-btn color="primary" @click="setPlane()">Send</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-divider />
    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              counter.hardware_version
            }}</v-list-item-title>
            <v-list-item-subtitle>Hardware Version</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              counter.creation_time | moment("lll")
            }}</v-list-item-title>
            <v-list-item-subtitle>Creation Time</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-divider />
    <v-subheader>Firmware</v-subheader>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="6" v-for="item in firmwareText" :key="item.value">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="counter.firmware">{{
                  counter.firmware[item.value]
                }}</span></v-list-item-title
              >
              <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>

      <v-row no-gutters justify="end">
        <v-col cols="auto">
          <v-btn
            small
            id="updateBtn"
            color="primary"
            @click="dialogFirmware = true"
            >UPDATE
            <v-icon right dark small> mdi-cloud-upload </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-divider />
    <v-subheader>Comments</v-subheader>
    <v-container fluid>
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-list-item
            two-line
            v-for="comment in paginedComments"
            :key="comment.comment"
          >
            <v-list-item-content>
              <v-list-item-title>{{ comment.comment }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ comment.user.firstname }} {{ comment.user.lastname }} -
                {{ comment.date | moment("lll") }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-pagination
            v-model="commentPage"
            :length="numberOfpage"
          ></v-pagination>
        </v-col>

        <v-col cols="12" class="mt-4" v-if="addComment">
          <v-textarea outlined label="Comment" v-model="comment"></v-textarea>
        </v-col>

        <v-col cols="auto" v-if="addComment === false">
          <v-btn small color="primary" @click="addComment = true"
            >Add Comment<v-icon right dark small> mdi-pencil </v-icon></v-btn
          >
        </v-col>

        <v-col cols="auto" v-if="addComment === true">
          <v-btn
            small
            text
            color="error"
            @click="
              addComment = false;
              comment = '';
            "
            >Annuler</v-btn
          >

          <v-btn small text color="success" @click="newComment">Valider</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <updateFirmwareDialog
      lazy
      @update="updateFirmware"
      v-model="dialogFirmware"
      :firmware="counter.firmware"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import updateFirmwareDialog from './updateFirmwareDialog.vue';

export default {
  props: ['counter'],
  components: {
    updateFirmwareDialog,
  },
  data() {
    return {
      dialogPrintLabel: false,
      dialogSetPlane: false,
      dialogCanPeriod: false,
      dialogSetImuRecord: false,
      dialogSetEsimTimeout: false,
      nbrItemPerPage: 5,
      dialogFirmware: false,
      addComment: false,
      comment: '',
      commentPage: 1,
      valueCanMinUsPeriod: 0,
      esimTimeout: 0,
      firmwareText: [
        { value: 'category', text: 'Category' },
        { value: 'address', text: 'Address' },
        { value: 'golden_version', text: 'Golden Version' },
        { value: 'ota_version', text: 'OTA version' },
        { value: 'current_version', text: 'Current version' },
        { value: 'crc_failed_count', text: 'CRC failed count' },
        { value: 'target_version', text: 'Target Version' },
        { value: 'bootloader_version', text: 'Bootloader Version' },
        { value: 'state', text: 'state' },
      ],
    };
  },
  computed: {
    ...mapGetters(['isSamsys']),
    numberOfpage() {
      if (this.counter.comments) {
        return Math.ceil(this.counter.comments.length / this.nbrItemPerPage);
      }
      return 0;
    },
    orderedComments() {
      if (this.counter.comments) {
        return [...this.counter.comments].sort((a, b) => (a.date > b.date ? -1 : 1));
      }
      return [];
    },
    paginedComments() {
      const start = (this.commentPage - 1) * this.nbrItemPerPage;
      const end = start + this.nbrItemPerPage;
      return this.orderedComments.slice(start, end);
    },
  },
  beforeMount() {
    this.getMe();
  },
  methods: {
    ...mapActions([
      'setCountersInfos',
      'getMe',
      'printLabelCounter',
      'setCounterInShipMode',
      'setCanMinUsPeriod',
      'setImuRecords',
      'setEsimTimout',
    ]),
    async sendCanMinUsPeriod() {
      await this.setCanMinUsPeriod({
        idCounter: this.counter.id,
        minUsPeriod: this.valueCanMinUsPeriod * 1000,
      });
      this.valueCanMinUsPeriod = 0;
      this.dialogCanPeriod = false;
    },
    async sendImuRecords(isEnable) {
      await this.setImuRecords({
        idCounter: this.counter.id,
        enable: isEnable,
      });
      this.dialogSetImuRecord = false;
    },
    async sendEsimTimout() {
      await this.setEsimTimout({
        idCounter: this.counter.id,
        esimTimout: this.esimTimeout,
      });
      this.esimTimeout = 0;
      this.dialogSetEsimTimeout = false;
    },
    printLabel(nbr) {
      this.printLabelCounter({ idCounter: this.counter.id, count: nbr });
      this.dialogPrintLabel = false;
    },
    setPlane() {
      this.setCounterInShipMode({ idCounter: this.counter.id });
      this.dialogSetPlane = false;
    },
    updateFirmware(val) {
      this.setCountersInfos({
        payload: {
          firmware: val,
        },
        idCounter: this.counter.id,
      });
    },
    async newComment() {
      this.addComment = false;
      await this.setCountersInfos({
        payload: {
          comment: this.comment,
        },
        idCounter: this.counter.id,
      });
      this.comment = '';
    },
  },
};
</script>
