<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <last-canbus-values :counter="id" />
      </v-col>
      <v-col cols="12">
        <datas-array-counter :counter="id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datasArrayCounter from '../../components/Datas/DatasArrayCounter.vue';
import LastCanbusValues from '../../components/Datas/LastCanbusValues.vue';

export default {
  props: ['id'],
  data() {
    return {
      loading: false,
    };
  },
  components: {
    datasArrayCounter,
    LastCanbusValues,
  },
  mounted() {},
  watch: {},
  computed: {
    rangeDate() {
      return {
        startDate: this.startDate.format(),
        endDate: this.endDate.format(),
      };
    },
  },
  methods: {},
};
</script>
