<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on">{{date | moment('l')}}</v-btn>
    </template>
    <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10),
    },
  },
  data() {
    return {
      date: this.value,
      menu: false,
    };
  },
  watch: {
    date() {
      this.$emit('input', this.date);
    },
  },
};
</script>
