<template>
    <v-card outlined tile color="blue-grey lighten-5">
      <v-card-title>
        Request:
        {{ config.change_request_t | moment("lll") }}</v-card-title
      >
      <v-card-subtitle>
        <b>Status: </b>
        <span
          :class="config.state === 'sended' ? 'success--text' : 'error--text'"
          >{{ config.state }}</span
        >
        <span
          :class="config.state === 'sended' ? 'success--text' : 'error--text'"
          v-if="config.applied_t"
        >
          ({{ config.applied_t | moment("lll") }})
        </span>
      </v-card-subtitle>
      <counter-canbus-configs-details-can :cans="config.cans" />
    </v-card>
</template>

<script>
import counterCanbusConfigsDetailsCan from './counterCanbusConfigsDetailsCan.vue';

export default {
  props: ['config'],
  components: {
    counterCanbusConfigsDetailsCan,
  },

};
</script>
