<template>
  <v-card>
    <v-card-title>
      MQTT
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-btn
        x-small
        color="blue"
        v-if="!!isSamsys"
        @click="sendMqttComd('GET')"
        >Force Record</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        x-small
        color="blue"
        v-if="!!isSamsys"
        @click="sendMqttComd('LOG0')"
        >Debug OFF</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        x-small
        color="blue"
        v-if="!!isSamsys"
        @click="sendMqttComd('LOG1')"
        >Debug ON</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        x-small
        color="red"
        v-if="!!isSamsys"
        @click="sendMqttComd('OFF')"
        >OFF</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        x-small
        color="red"
        v-if="!!isSamsys"
        @click="sendMqttComd('RST')"
        >Reset</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        x-small
        color="red"
        v-if="!!isSamsys"
        @click="sendMqttComd('PLANE')"
        >Set Ship Mode</v-btn
      >
    </v-card-title>
    <v-card-text style="height: 550px; overflow-y: scroll">
      <div
        v-for="(log, index) in getMqttRecieved"
        :key="index"
        class="logo">
        <p style="color: blue; word-break: break-all">{{ new Date(log.t).toISOString() }}</p>
        <p style="color: green; overflow-wrap: break-all">{{ log.topic }}</p>
        <p style="left: 400px; color: black; overflow-wrap: break-all">{{ log.message }}</p>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: ['counter'],
  computed: {
    ...mapGetters(['isSamsys', 'getMqttRecieved']),
  },
  methods: {
    ...mapMutations(['cleanMqttRecieved']),
    ...mapActions(['mqttConnect', 'mqttSub', 'mqttUnSub', 'mqttPub']),
    sendMqttComd(acommand) {
      this.mqttPub({ topic: `counter/${this.counter}/to/data`, message: acommand })
    },
  },
  watch: {
    counter(newVal, oldVal) {
      this.mqttUnSub(`counter/${oldVal}/#`)
      this.cleanMqttRecieved()
      this.mqttSub(`counter/${newVal}/#`)
    },
    /* () {
      console.log(this.counter);
      this.mqttSub(`counter/${this.counter}/#`);
    }, */
  },
  async created() {
    this.mqttConnect()
    this.mqttSub(`counter/${this.counter}/#`)
  },
  beforeDestroy() {
    this.mqttUnSub(`counter/${this.counter}/#`)
    this.cleanMqttRecieved()
  },
  data() {
    return {
      dialogSetPlane: false,
      logs: [],
    }
  },
}
</script>
<style>
.mqtt_date {
  font-family: 'Myriad Pro', 'sans-serif';
  font-size: 8pt;
  line-height: 0.1;
  color: #7736c2;
}
,
.mqtt_topic {
  font-family: 'Myriad Pro', 'sans-serif';
  font-size: 8pt;
  line-height: 0.1;
  color: #00b527;
}
.logo div {
  margin: 0;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 2.3;
}
.logo p {
  margin: 0;
  padding: 10px;
  display: inline-block;
  line-height: 1;
  height: 1;
  background: white;
  margin-bottom: 0;
  margin-top: 0;
  vertical-align: top;
}
</style>
