<template>
  <div id="map"></div>
</template>

<script>
import L from 'leaflet'

export default {
  data() {
    return {
      map: null,
      geoLayer: L.layerGroup(),
    }
  },
  props: ['geolocalisation', 'selectPeriod'],
  mounted() {
    this.initMap()
  },
  computed: {
    geolocalisationFiltered() {
      if (this.selectPeriod === null) {
        return this.geolocalisation
      }
      return {}
    },
  },
  watch: {
    geolocalisationFiltered() {
      this.geoLayer.clearLayers()
      if (this.geolocalisationFiltered.type) {
        const layer = L.geoJSON(this.geolocalisationFiltered).addTo(this.geoLayer)
        this.map.fitBounds(layer.getBounds())
      }
    },
  },
  methods: {
    initMap() {
      this.map = L.map('map').setView([50.63249006935536, 3.0206917682696584], 13)
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map)
      this.geoLayer.addTo(this.map)
    },
  },
}
</script>

<style scoped>
#map {
  height: 300px;
  z-index: 0;
}
</style>
