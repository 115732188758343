<template>
  <v-card class="pa-2" outlined tile>
    <v-card-title> Network Infos </v-card-title>
    <v-divider />

    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ iccid.iccid }}</v-list-item-title>
            <v-list-item-subtitle>ICCID</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ iccid.imei }}</v-list-item-title>
            <v-list-item-subtitle>IMEI</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              iccid.dateShipped | moment("lll")
            }}</v-list-item-title>
            <v-list-item-subtitle>Date Shipped (SIM)</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              iccid.dateActivated | moment("lll")
            }}</v-list-item-title>
            <v-list-item-subtitle>Date Activated (SIM)</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ iccid.sim_status }}</v-list-item-title>
            <v-list-item-subtitle>SIM Status</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-divider />
    <v-subheader>Last Network Session</v-subheader>
    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="iccid.lastSession.dateSessionStarted">{{
                iccid.lastSession.dateSessionStarted | moment("lll")
              }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>Start session</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="iccid.lastSession.dateSessionEnded">{{
                iccid.lastSession.dateSessionEnded | moment("lll")
              }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>End session</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              iccid.lastSession.ipAddress
            }}</v-list-item-title>
            <v-list-item-subtitle>IP Address</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <div v-if="iccidUsage.length === 1">
      <v-divider />
      <v-subheader
        > <span>Usages (</span>
        <span v-if="iccidUsage[0].usage.t"
          >{{ iccidUsage[0].usage.t | moment("lll") }}</span
        >)</v-subheader
      >
      <v-row>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                iccidUsage[0].usage.ctdDataUsage * 1e-6
              }}</v-list-item-title>
              <v-list-item-subtitle>Data (MB)</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                iccidUsage[0].usage.ctdSMSUsage
              }}</v-list-item-title>
              <v-list-item-subtitle>SMS</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                iccidUsage[0].usage.ctdSessionCount
              }}</v-list-item-title>
              <v-list-item-subtitle>Sessions</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                iccidUsage[0].usage.ratePlan
              }}</v-list-item-title>
              <v-list-item-subtitle>Rate Plan</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['iccid', 'iccidUsage'],
};
</script>
