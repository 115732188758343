<template>
  <v-card style="width: 100%">
    <v-toolbar color="secondary" dense dark>
      <btnHours v-model="startDate" class="mr-2" />
      <span class="hidden-sm-and-down"> au </span>
      <btnHours v-model="endDate" class="ml-2" />
      <v-spacer></v-spacer>
      <v-btn
        small
        color="warning"
        v-if="typeOfDataSelected === 'canbus_j1939'"
        @click="download"
        :loading="downloading"
        >download</v-btn
      >
      <v-btn small color="warning" @click="refresh">refresh</v-btn>
    </v-toolbar>
    <v-container class="pt-5">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-select
            dense
            :items="typeOfData"
            label="Type Of Data"
            v-model="typeOfDataSelected"
          />
        </v-col>
        <!-- <v-col cols="12" md="6">
          <v-text-field
            label="Search"
            dense
            prepend-icon="mdi-magnify"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-row dense>
        <!-- <v-col>
          <v-btn small color="secondary">export</v-btn>
        </v-col> -->
      </v-row>
    </v-container>
    <v-data-table
      :headers="headerTable"
      :items="dataTable"
      :options.sync="options"
      :server-items-length="numberOfData"
      :loading="loading"
      dense
    ></v-data-table>
    <line-chart
      v-if="loaded"
      :chartdata="chartdata"
      :options="options"/>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import btnHours from '@/components/Dates/btnDateAndHours.vue';
// import LineChart from '@/components/LineChart';

export default {
  components: {
    btnHours,
  },
  props: ['counter'],
  data() {
    return {
      chartLoaded: false,
      chartdata: null,
      loading: false,
      downloading: false,
      typeOfDataSelected: '',
      typeOfData: [
        { text: 'Classic Data', value: 'counter_datas' },
        // { text: 'Canbus Raw', value: 'canbus_raw' },
        { text: 'Canbus j1939', value: 'canbus_j1939' },
        { text: 'Canbus Values', value: 'canbus_values' },
        { text: 'Canbus Raw', value: 'canbus_raw' },
      ],
      datas: [],
      options: {},
      startDate: moment().utc().subtract(1, 'days'),
      endDate: moment().utc(),
    };
  },
  watch: {
    counter() {
      this.refresh();
    },
    typeOfDataSelected() {
      this.refresh();
    },
    options: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['counterDatas', 'canbusValues', 'canbusJ1939', 'canbusRaw']),
    dataTable() {
      if (this.typeOfDataSelected === 'counter_datas') {
        return this.counterDatas.datas;
      }

      if (this.typeOfDataSelected === 'canbus_values') {
        return this.canbusValues;
      }

      if (this.typeOfDataSelected === 'canbus_j1939') {
        return this.canbusJ1939;
      }

      if (this.typeOfDataSelected === 'canbus_raw') {
        return this.canbusRaw;
      }
      return [];
    },
    numberOfData() {
      return this.counterDatas.count || 10000;
    },
    headerTable() {
      if (this.typeOfDataSelected === 'counter_datas') {
        return [
          {
            text: 'loc',
            align: 'start',
            sortable: false,
            value: 'loc.coordinates',
          },
          { text: 't_data', value: 'properties.t', sortable: false },
          { text: 'pitch', value: 'properties.pitch', sortable: false },
          { text: 'roll', value: 'properties.roll', sortable: false },
          { text: 'accuracy', value: 'properties.accuracy', sortable: false },
          { text: 'v_ext', value: 'properties.v_ext', sortable: false },
          {
            text: 'temperature',
            value: 'properties.temperature',
            sortable: false,
          },
          { text: 'vibration', value: 'properties.vibration', sortable: false },
          { text: 'v_bat', value: 'properties.v_bat', sortable: false },
          { text: 'speed', value: 'properties.speed', sortable: false },
        ];
      }

      if (this.typeOfDataSelected === 'canbus_values') {
        return [
          {
            text: 'loc',
            align: 'start',
            sortable: false,
            value: 'loc.coordinates',
          },
          { text: 't_data', value: 't_data', sortable: false },
          { text: 'name', value: 'name', sortable: false },
          { text: 'value', value: 'value', sortable: false },
        ];
      }

      if (this.typeOfDataSelected === 'canbus_j1939') {
        return [
          {
            text: 'loc',
            align: 'start',
            sortable: false,
            value: 'loc.coordinates',
          },
          { text: 't_data', value: 't', sortable: false },
          { text: 'fuel_rate', value: 'fuel_rate', sortable: false },
          {
            text: 'engine hours',
            value: 'engine_total_hours_of_operation',
            sortable: false,
          },
        ];
      }

      if (this.typeOfDataSelected === 'canbus_raw') {
        return [
          {
            text: 'loc',
            align: 'start',
            sortable: false,
            value: 'loc.coordinates',
          },
          { text: 't_data', value: 'properties.t', sortable: false },
          { text: 'can_data', value: 'properties.can_data', sortable: false },
          { text: 'can_id', value: 'properties.can_id', sortable: false },
        ];
      }

      return [];
    },
  },
  methods: {
    ...mapActions([
      'getHistoricalDatas',
      'getCanbusValues',
      'getHistoricalDatasCanbusJ1939',
      'getHistoricalDatasCanbusRaw',
      'downloadRawData',
    ]),
    async download() {
      this.downloading = true;

      const data = await this.downloadRawData({
        idCounter: this.counter,
        startDate: moment(this.startDate).utc().format(),
        endDate: moment(this.endDate).utc().format(),
        type: this.typeOfDataSelected,
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `export_${this.typeOfDataSelected}_${
          this.counter
        }_${new Date().getTime()}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();

      this.downloading = false;
    },
    async refresh() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      if (this.typeOfDataSelected === 'counter_datas') {
        await this.getHistoricalDatas({
          idCounter: this.counter,
          startDate: moment(this.startDate).utc().format(),
          endDate: moment(this.endDate).utc().format(),
          limit: itemsPerPage,
          page,
        });
      }
      if (this.typeOfDataSelected === 'canbus_values') {
        await this.getCanbusValues({
          idCounter: this.counter,
          startDate: moment(this.startDate).utc().format(),
          endDate: moment(this.endDate).utc().format(),
          limit: itemsPerPage,
          page,
        });
      }
      if (this.typeOfDataSelected === 'canbus_j1939') {
        await this.getHistoricalDatasCanbusJ1939({
          idCounter: this.counter,
          startDate: moment(this.startDate).utc().format(),
          endDate: moment(this.endDate).utc().format(),
          limit: itemsPerPage,
          page,
        });
      }
      if (this.typeOfDataSelected === 'canbus_raw') {
        await this.getHistoricalDatasCanbusRaw({
          idCounter: this.counter,
          startDate: moment(this.startDate).utc().format(),
          endDate: moment(this.endDate).utc().format(),
          limit: itemsPerPage,
          page,
        });
      }
      this.loading = false;
    },
  },
};
</script>
