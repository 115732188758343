<template>
  <v-card outlined tile>
    <v-card-title> Canbus Values Counter Configs </v-card-title>
    <v-divider></v-divider>
  <counter-canbus-values-configs-list :configs="canbusValuesConfig" />
  </v-card>
</template>

<script>
import moment from 'moment';
import counterCanbusValuesConfigsList from './counterCanbusValuesConfigsList.vue';

export default {
  props: ['configs'],
  components: {
    counterCanbusValuesConfigsList,
  },
  computed: {
    canbusValuesConfig() {
      return this.configs.map((elem) => {
        const locale = moment.locale();
        let name = elem.name.find((tps) => tps.languages.includes(locale));
        if (name === undefined) {
          name = elem.name.find((tps) => tps.languages.includes('default'));
        }
        return {
          nameTable: name.text,
          ...elem,
        };
      });
    },
  },

};
</script>
