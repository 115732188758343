<template>
  <v-data-table
    :headers="headers"
    :items="countersAdminRights"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="primary" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="500px" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="success"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              ADD
              <v-icon small right dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" v-if="editedIndex === -1">
                    <v-select
                      :items="adminAccount"
                      v-model="editedItem.id"
                      item-text="name"
                      return-object
                      label="Admin"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model.number="editedItem.access_level"
                      type="number"
                      label="Access Level"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close"> Cancel </v-btn>
              <v-btn color="primary" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['id'],
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
        access_level: 0,
        counter: '',
      },
      defaultItem: {
        id: '',
        name: '',
        access_level: 0,
        counter: '',
      },
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Access Level',
          value: 'access_level',
          align: 'right',
          sortable: false,
        },
        {
          text: 'Actions',
          align: 'right',
          value: 'actions',
          sortable: false,
        },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters(['countersAdminRights', 'adminAccount']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Access' : 'Edit Item';
    },
  },
  watch: {
    id(val) {
      if (val) {
        this.refresh();
      }
    },
    dialog(val) {
      if (val && this.editedIndex === -1) {
        this.getAdminAccounts();
      }
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions([
      'getCountersAdminRights',
      'editCountersAdminRights',
      'getAdminAccounts',
      'createCountersAdminRights',
      'deleteCountersAdminRights',
    ]),
    refresh() {
      this.getCountersAdminRights({ counter: this.id });
    },
    editItem(item) {
      this.editedIndex = this.countersAdminRights.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.countersAdminRights.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteCountersAdminRights({
        idAdmin: this.editedItem.id,
        counter: this.editedItem.counter,
      });
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        console.log(this.editedItem);
        await this.editCountersAdminRights({
          idAdmin: this.editedItem.id,
          counter: this.editedItem.counter,
          accessLevel: this.editedItem.access_level,
        });
      } else {
        this.createCountersAdminRights([
          {
            idAdmin: this.editedItem.id.id,
            name: this.editedItem.id.name,
            counter: this.id,
            access_level: this.editedItem.access_level,
          },
        ]);
      }
      this.close();
    },
  },
};
</script>
