<template>
  <div>
    <v-app-bar
      fixed
      app
      flat
      color="primary"
      dark>
      <v-btn
        icon
        @click="$router.push('/counters')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="hidden-sm-and-down">Counter</v-toolbar-title>
      <v-autocomplete
        :items="countersLight"
        item-text="id"
        item-value="id"
        dense
        solo
        hide-details
        hide-selected
        color="white"
        v-model="counter"
        class="ml-2" />
      <template v-slot:extension>
        <v-tabs
          centered
          grow
          background-color="primary"
          dark
          color="yellow">
          <v-tab
            exact
            :to="`/counters/${counter}`"
            >Infos</v-tab
          >
          <v-tab :to="`/counters/${counter}/datas`">Datas</v-tab>
          <v-tab :to="`/counters/${counter}/can`">CAN Config</v-tab>
          <v-tab :to="`/counters/${counter}/rights`">Rights</v-tab>
          <v-tab :to="`/counters/${counter}/bleScan`">BLE Scan</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['id'],
  components: {},
  data() {
    return {
      counter: '',
    }
  },
  created() {
    this.getCountersLight()
  },
  mounted() {
    this.counter = this.id
  },
  watch: {
    counter(val) {
      this.$router
        .push({ name: this.$route.name, query: this.$route.query, params: { id: val } })
        .catch(() => {})
    },
  },
  computed: {
    ...mapGetters(['countersLight']),
  },
  methods: {
    ...mapActions(['getCountersLight']),
  },
}
</script>
