<template>
  <v-card>
    <v-card-title> Write Canbus: "{{ config.name }}" </v-card-title>
    <v-card-text>
      <v-text-field type="number" v-model="data" label="Value"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="error" @click="cancel">CANCEL</v-btn>
      <v-btn small color="success" @click="send">SEND</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import canbusValuesServices from '@/services/canbusValues.services';

export default {
  data() {
    return {
      data: '',
    };
  },
  props: ['config', 'counter'],
  methods: {
    cancel() {
      this.data = '';
      this.$emit('cancel');
    },
    async send() {
      const data = parseFloat(this.data);
      await canbusValuesServices.writeCanbusValues(this.config.id, {
        idCounter: this.counter,
        data,
      });
      this.cancel();
    },
  },
};
</script>
