<template>
  <v-card outlined tile>
    <v-card-title> Canbus Counter Configs </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="configs.length">
      <counterCanbusConfigsDetails :config="config" />
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="this.configs.length"
        ></v-pagination>
      </div>
    </v-card-text>
    <v-card-text v-else>
      <div class="text-center my-5" >
        No config.
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        color="secondary"
        v-if="configs.length"
        @click="downloadConfig(config)"
        >Download Config</v-btn
      >
      <v-btn color="success" small>SEND NEW CONFIG</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import counterCanbusConfigsDetails from './counterCanbusConfigsDetails.vue';

export default {
  props: ['configs'],
  components: {
    counterCanbusConfigsDetails,
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    config() {
      return this.configs[this.page - 1];
    },
  },
  methods: {
    downloadConfig(config) {
      const toDownload = JSON.stringify(config.cans, null, 2);
      const filename = `Canbus_counter_configs_${config.counter}.json`;
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:text/plain;charset=utf-8,${encodeURIComponent(toDownload)}`,
      );
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
