<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on">{{time}}</v-btn>
    </template>
    <v-time-picker
        v-if="menu"
        format="24hr"
        v-model="time"
        full-width
        @click:minute="$refs.menu.save(time)"
    >
    </v-time-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: String,
      default: moment(new Date()).format('HH:mm'),
    },
  },
  data() {
    return {
      time: this.value,
      menu: false,
    };
  },
  watch: {
    time() {
      this.$emit('input', this.time);
    },
  },
};
</script>
