<template>
  <v-card
    class="pa-2"
    outlined
    tile>
    <v-card-title>
      Associations <v-spacer />
      <v-btn
        color="primary"
        small
        @click="addCluster">
        ADD Cluster
      </v-btn>
    </v-card-title>
    <v-divider v-if="addDialog" />
    <v-container v-if="addDialog">
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :items="clusters"
            label="Clusters"
            item-text="name"
            return-object
            v-model="selectedCluster"></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <menu-date
            label="Start date"
            v-model="addStartDate" />
        </v-col>
        <v-col cols="6">
          <menu-date
            label="End date"
            v-model="addEndDate" />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer />
      <v-btn
        small
        :disabled="!isValidDate || !selectedCluster.id"
        v-if="addDialog"
        color="primary"
        @click="sendNewCluster">
        add
      </v-btn>
    </v-card-actions>
    <v-divider />
    <v-container>
      <v-row>
        <v-col cols="12">
          <div id="visualization"></div>
        </v-col>
      </v-row>
    </v-container>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y>
    </v-menu>
  </v-card>
</template>

<script>
import { Timeline, DataSet } from 'vis-timeline/standalone';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';
import menuDate from '../Dates/menuDate.vue';

export default {
  props: ['associations', 'counterMachine', 'counter'],
  components: {
    menuDate,
  },
  data() {
    return {
      timeline: '',
      dates: [],
      addDialog: false,
      showMenu: false,
      selectedCluster: {},
      addStartDate: moment().startOf('day').format('YYYY-MM-DD'),
      addEndDate: moment().startOf('day').add(1, 'years').format('YYYY-MM-DD'),
      tpsAtt: {
        id: uuidv4(),
        group: 1,
        content: '?',
        start: moment().startOf('day').utc().toISOString(),
        end: moment().startOf('day').add(1, 'years').utc().toISOString(),
        start_date: moment().startOf('day').utc().toISOString(),
        end_date: moment().startOf('day').add(1, 'years').utc().toISOString(),
        cluster: '',
        className: 'red',
      },
      defaultAtt: {
        id: uuidv4(),
        group: 1,
        content: '?',
        start: moment().startOf('day').utc().toISOString(),
        end: moment().startOf('day').add(1, 'years').utc().toISOString(),
        start_date: moment().startOf('day').utc().toISOString(),
        end_date: moment().startOf('day').add(1, 'years').utc().toISOString(),
        cluster: '',
        className: 'red',
      },
      x: 0,
      y: 0,
      items: new DataSet([]),
      groups: [
        {
          id: 1,
          content: 'Clusters',
        },
        {
          id: 2,
          content: 'Machines',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['clusters']),
    isValidDate() {
      if (this.associations.length === 1) {
        const addStartDateObj = moment(this.addStartDate);
        const addEndDateObj = moment(this.addEndDate);
        const superposed = this.associations[0].owner.find((elem) => {
          const elemStartDate = moment(elem.start_date);
          const elemEndDate = elem.end_date
            ? moment(elem.end_date)
            : moment(new Date(8640000000000000));
          if (addStartDateObj < elemEndDate && addEndDateObj > elemStartDate) {
            return true;
          }

          return false;
        });

        return !superposed;
      }
      return true;
    },
  },
  mounted() {
    this.init();
    this.draw();
  },
  watch: {
    isValidDate(val) {
      this.tpsAtt.className = val ? 'green' : 'red';
      if (this.addDialog) {
        this.items.update(this.tpsAtt);
      }
    },
    addStartDate(val) {
      const tpsDate = moment(val).toISOString();
      this.tpsAtt.start = tpsDate;
      this.tpsAtt.start_date = tpsDate;
      if (this.addDialog) {
        this.items.update(this.tpsAtt);
      }
    },
    addEndDate(val) {
      const tpsDate = moment(val).add(1, 'day').toISOString();
      this.tpsAtt.end = tpsDate;
      this.tpsAtt.end_date = tpsDate;
      if (this.addDialog) {
        this.items.update(this.tpsAtt);
      }
    },
    associations() {
      this.draw();
    },
    counterMachine() {
      this.draw();
    },
    selectedCluster(val) {
      this.tpsAtt.content = Object.keys(val).length ? val.name : '?';
      this.tpsAtt.cluster = Object.keys(val).length ? val.id : '';
      if (this.addDialog) {
        this.items.update(this.tpsAtt);
      }
    },
  },
  methods: {
    ...mapActions(['getClusters', 'addCounterAssociations']),
    addCluster() {
      this.addDialog = !this.addDialog;
      this.selectedCluster = {};
      if (this.addDialog) {
        this.getClusters();
        this.tpsAtt.id = uuidv4();
        this.tpsAtt.className = this.isValidDate ? 'green' : 'red';
        this.items.add(this.tpsAtt);
      } else {
        this.items.remove(this.tpsAtt);
        this.tpsAtt = { ...this.defaultAtt };
        this.addStartDate = moment(this.tpsAtt.start).format('YYYY-MM-DD');
        this.addEndDate = moment(this.tpsAtt.end).format('YYYY-MM-DD');
      }
    },
    init() {
      const container = document.getElementById('visualization');
      const options = {
        start: moment().subtract(1, 'years'),
        end: moment().add(1, 'years'),
        zoomMin: 20000000,
        zoomMax: 200720000000,
        snap: null,
        selectable: true,
        moment,
        stack: false,
        autoResize: true,
      };
      this.timeline = new Timeline(container, this.items, this.groups, options);
      this.timeline.on('click', this.onClickTimeline);
    },
    onClickTimeline(e) {
      this.showMenu = false;
      this.x = e.event.clientX;
      this.y = e.event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    draw() {
      this.items.clear();
      let associationsFormat = [];
      if (this.associations.length === 1) {
        associationsFormat = associationsFormat.concat(
          this.associations[0].owner.map((owner) => ({
            id: uuidv4(),
            group: 1,
            content: owner.cluster.name,
            start: owner.start_date,
            end: owner.end_date
              ? owner.end_date
              : moment(new Date(8640000000000000)).utc().toISOString(),
            start_date: owner.start_date,
            end_date: owner.end_date,
            cluster: owner.cluster,
          }))
        );
      }
      if (this.counterMachine.length > 0) {
        associationsFormat = associationsFormat.concat(
          this.counterMachine.map((item) => ({
            id: uuidv4(),
            group: 2,
            content: item.machine.map((elem) => elem.name).join(', '),
            start: item.start_date,
            end: item.end_date
              ? item.end_date
              : moment(new Date(8640000000000000)).utc().toISOString(),
            start_date: item.stat_date,
            end_date: item.end_date,
            machine: item.machine,
          }))
        );
      }
      this.items.add(associationsFormat);
    },
    async sendNewCluster() {
      const payload = {
        counter: this.counter,
        cluster: this.selectedCluster.id,
        startDate: this.tpsAtt.start_date,
        endDate: this.tpsAtt.end_date,
      };

      await this.addCounterAssociations([payload]);
      this.addCluster();
    },
  },
};
</script>

<style scoped>
#visualization {
  width: 100%;
  height: 100%;
}

.vis-item.red {
  background-color: red;
  border-color: darkred;
  color: white;
  font-family: monospace;
  box-shadow: 0 0 10px gray;
}

.vis-item.green {
  background-color: green;
  border-color: darkgreen;
  color: white;
  font-family: monospace;
  box-shadow: 0 0 10px gray;
}
</style>
