import Api from '@/services/Api'

export default {
  getGeolocation(idCounter, startDate, endDate) {
    return Api().get(`countersGeolocations/${idCounter}`, {
      params: {
        startDate,
        endDate,
      },
    })
  },
}
