<template>
  <v-data-table
    dense
    :headers="headers"
    :items="counters"
    :items-per-page="10"
    sort-by="t_serv"
    sort-desc
  >
    <template v-slot:[`item.id`]="{ item }">
      <v-icon small @click="$router.push(`/counters/${item.id}`)">
        mdi-information
      </v-icon>

      {{ item.id }}

    </template>
    <template v-slot:[`item.t_serv`]="{ item }">
      <span v-if="item.t_serv">{{
        item.t_serv | moment('lll')
      }}</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    counters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Id',
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Last_t_serv',
          align: 'right',
          sortable: true,
          value: 't_serv',
        },
        {
          text: 'Firmware',
          align: 'right',
          sortable: true,
          value: 'firmware',
        },
        {
          text: 'owner',
          align: 'right',
          sortable: true,
          value: 'owner',
        },
      ],
    };
  },
  methods: {
    bat_color(val) {
      if (val > 100) {
        return 'green';
      }
      return 'grey lighten-2';
    },
  },
};
</script>
