import Api from '@/services/Api'

export default {
  getCounterScan(idCounter, startDate, endDate) {
    return Api().get(`bleScan/counter/${idCounter}`, {
      params: {
        startDate,
        endDate,
      },
    })
  },
  getTagScan(idTag, startDate, endDate) {
    return Api().get(`bleScan/tag/${idTag}`, {
      params: {
        startDate,
        endDate,
      },
    })
  },
}
