<template>
  <v-card class="pa-2" outlined tile>
    <v-card-title> Last data </v-card-title>
    <v-divider />
    <v-subheader>History Data</v-subheader>
    <v-container fluid v-if="lastData.lastHistoryData">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                lastData.lastHistoryData._id
              }}</v-list-item-title>
              <v-list-item-subtitle>_id</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                lastData.lastHistoryData.t_data
              }}</v-list-item-title>
              <v-list-item-subtitle>t_data</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                lastData.lastHistoryData.t_serv
              }}</v-list-item-title>
              <v-list-item-subtitle>t_serv</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                lastData.lastHistoryData.packet_number
              }}</v-list-item-title>
              <v-list-item-subtitle>packet_number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <v-subheader>Counter Data</v-subheader>
    <v-container fluid v-if="lastData.lastCounterData">
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          v-for="header in lastCounterDataHeaders"
          :key="header.value"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                get(lastData.lastCounterData, header.value)
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ header.text }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import get from 'lodash.get';

export default {
  props: {
    lastData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      lastCounterDataHeaders: [
        { text: '_id', value: 'id' },
        { text: 't_serv', value: 't_serv' },
        { text: 't_data', value: 'properties.t' },
        { text: 'packet_number', value: 'packet_number' },
        { text: 'v_bat', value: 'properties.v_bat' },
        { text: 'v_ext', value: 'properties.v_ext' },
        { text: 'vibration', value: 'properties.vibration' },
        { text: 'accuracy', value: 'properties.accuracy' },
        { text: 'pitch', value: 'properties.pitch' },
        { text: 'roll', value: 'properties.roll' },
        { text: 'yaw', value: 'properties.yaw' },
        { text: 'temperature', value: 'properties.temperature' },
        { text: 'speed', value: 'properties.speed' },
        { text: 'aux_state', value: 'properties.aux_state' },
        { text: 'aux_count', value: 'properties.aux_count' },
        { text: 'aux_rpm', value: 'properties.aux_rpm' },
        { text: 'aux_state_2', value: 'properties.aux_state_2' },
        { text: 'aux_count_2', value: 'properties.aux_count_2' },
        { text: 'aux_rpm_2', value: 'properties.aux_rpm_2' },
        { text: 'road', value: 'road' },
      ],
    };
  },
  methods: {
    get,
  },
};
</script>
