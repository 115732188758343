<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <counter-resume :counter="counterInfos" />
      </v-col>
      <v-col>
        <counter-last-data :lastData='lastDataDate' />
      </v-col>
      <v-col cols="12" v-if="iccid.length === 1">
        <counter-ICCID :iccid="iccid[0]" :iccidUsage="iccidUsage"/>
      </v-col>
      <v-col cols="12">
        <counter-association
          :associations="associations"
          :counterMachine="counterMachines"
          :counter="id"
        />
      </v-col>
      <v-col cols="12">
        <mqtt-console
          :counter="id"
        />
      </v-col>
      <!-- <v-col cols="12">
        <v-card style="width: 100%; height: 40vh">
          <counter-diagram
            :title="`Amount of datas per day for ${id}`"
            :datas="diagramData[0]"
          />
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import counterResume from '@/components/counters/counterResume.vue';
import counterAssociation from '@/components/counters/counterAssociation.vue';
// import counterDiagram from '@/components/counters/counterDiagram.vue';
import counterICCID from '@/components/counters/counterICCID.vue';
import mqttConsole from '@/components/counters/mqttConsole.vue';
import counterLastData from '../../components/counters/counterLastData.vue';

export default {
  props: ['id'],
  components: {
    counterResume,
    counterAssociation,
    // counterDiagram,
    counterICCID,
    mqttConsole,
    counterLastData,
  },
  data() {
    return {
      loading: false,
      startDate: moment().utc().subtract(6, 'months').format('YYYY-MM-DD'),
      endDate: moment().utc().format('YYYY-MM-DD'),
    };
  },
  mounted() {
    this.refresh();
  },
  watch: {
    id(val) {
      if (val) {
        this.refresh();
      }
    },
  },
  computed: {
    ...mapGetters([
      'counterInfos',
      'associations',
      'counterMachines',
      'diagramData',
      'iccid',
      'iccidUsage',
      'lastDataDate',
    ]),
    rangeDate() {
      return {
        startDate: moment(this.startDate).utc().format(),
        endDate: moment(this.endDate).add(1, 'day').utc().format(),
      };
    },
  },
  methods: {
    ...mapActions([
      'getCountersInfos',
      'getCounterAssociations',
      'getCountersMachines',
      'getCountersDiagramDatas',
      'getICCID',
      'getICCIDUsage',
      'getLastDataDate',
    ]),
    async refresh() {
      this.getCountersInfos({ idCounter: this.id });
      this.getCounterAssociations({ idCounter: [this.id] });
      this.getCountersMachines({ idCounter: [this.id] });
      this.getICCID([this.id]);
      this.getICCIDUsage([this.id]);
      this.getLastDataDate(this.id);
      this.loading = true;
      await this.getCountersDiagramDatas({
        idCounter: [this.id],
        range: this.rangeDate,
      });
      this.loading = false;
    },
  },
};
</script>
