<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <counter-canbus-configs :configs="canbusCounterConfigs" />
      </v-col>
    </v-row>
        <v-row>
      <v-col cols="12">
        <counter-canbus-values-configs :configs="canbusValuesConfigs" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import counterCanbusConfigs from '@/components/counters/counterCanbusConfigs.vue';
import { mapActions, mapGetters } from 'vuex';
import counterCanbusValuesConfigs from '../../components/counters/counterCanbusValuesConfigs.vue';

export default {
  props: ['id'],
  components: {
    counterCanbusConfigs,
    counterCanbusValuesConfigs,
  },
  watch: {
    id(val) {
      if (val) {
        this.refresh();
      }
    },
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters(['canbusCounterConfigs', 'canbusValuesConfigs']),
  },
  methods: {
    ...mapActions(['getCanbusCounterConfigs', 'getCanbusValuesConfigs']),
    refresh() {
      this.getCanbusCounterConfigs({ idCounter: [this.id] });
      this.getCanbusValuesConfigs({ idCounter: [this.id] });
    },
  },
};
</script>
