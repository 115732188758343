<template>
  <div ref="visualization"></div>
</template>

<script>
import { Timeline } from 'vis-timeline/standalone'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
export default {
  props: ['scan', 'date'],

  mounted() {
    this.init()
  },

  watch: {
    groups() {
      if (this.timeline) {
        this.timeline.setGroups(this.groups)
      }
    },
    options() {
      if (this.timeline) {
        this.timeline.setOptions(this.options)
      }
    },

    items() {
      if (this.timeline) {
        this.timeline.setItems(this.items)
      }
    },
  },

  computed: {
    items() {
      return this.scan.flatMap((s) => {
        return s.scan.map((d) => ({
          id: uuidv4(),
          group: s.beacon,
          content: `dist: ${d.dist} - duration: ${(
            (moment(d.end).toDate() - moment(d.start).toDate()) /
            (1000 * 60)
          ).toFixed(2)} min`,
          start: moment(d.start).toDate(),
          end: moment(d.end).toDate(),
        }))
      })
    },
    groups() {
      return this.scan
        .map((s) => ({
          id: s.beacon,
          content: s.beacon,
        }))
        .sort((a, b) => (a.id > b.id ? 1 : -1))
    },
    options() {
      return {
        start: this.date.startOf('day').toDate(),
        end: this.date.endOf('day').toDate(),
        min: this.date.startOf('day').toDate(),
        max: this.date.endOf('day').toDate(),
        snap: null,
        selectable: false,
        moment,
        stack: false,
        autoResize: true,
        orientation: 'top',
      }
    },
  },

  methods: {
    init() {
      const container = this.$refs.visualization
      this.timeline = new Timeline(container, this.items, this.groups, this.options)
      this.timeline.on('mouseOver', this.mouserOver)
      container.addEventListener('mouseleave', this.mouseOut)
    },
    mouseOut() {
      this.$emit('select', null)
    },
    mouserOver(e) {
      if (e.group) {
        this.$emit(
          'select',
          this.items.filter((i) => i.group === e.group)
        )
      }
    },
  },
}
</script>

<style scoped>
#visualization {
  width: 100%;
  height: 100px;
}
</style>
