<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-container fluid>
        <v-select
          v-if="tpsFirmware"
          :items="items"
          label="Firmware Target Version"
          v-model="tpsFirmware.target_version"
        ></v-select>
      </v-container>
      <v-card-actions class="justify-end">
        <v-btn color="error" text @click="dialog = false">Annuler</v-btn>
        <v-btn color="success" text @click="update()"
          >UPDATE</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['value', 'firmware'],
  data() {
    return {
      dialog: this.value,
      tpsFirmware: { ...this.firmware },
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
      if (val) {
        this.getFirmwareLists();
      }
    },
    firmware(val) {
      this.tpsFirmware = { ...val };
    },
    dialog(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.getFirmwareLists();
  },
  computed: {
    ...mapGetters(['firmwareList']),
    changes() {
      // eslint-disable-next-line camelcase
      return this.firmware?.target_version === this.tpsFirmware?.target_version;
    },
    items() {
      return this.firmwareList.map((elem) => elem.id);
    },
  },
  methods: {
    ...mapActions(['getFirmwareLists']),
    update() {
      this.$emit('update', this.tpsFirmware.target_version);
      this.dialog = false;
    },
  },
};
</script>
