<template>
  <v-card style="width: 100%">
    <v-toolbar color="secondary" dark dense>
      <v-toolbar-title> Canbus Last Data </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        solo
        light
        hide-details
        dense
        prepend-inner-icon="mdi-magnify"
        v-model="search"
        clearable
      ></v-text-field>
    </v-toolbar>
    <v-container fluid class="listconfig">
      <span v-if="params.length === 0">No DATA</span>
      <v-list two-line>
        <template v-for="(item, index) in params">
          <v-list-item :key="item.id">
            <v-list-item-content>
              <v-list-item-title
                ><b>{{ item.name }}</b></v-list-item-title
              >

              <v-list-item-subtitle>
                {{ item.value }} {{ item.unit }}
              </v-list-item-subtitle>

              <v-list-item-subtitle>
                <span v-if="item.t_data">{{
                  item.t_data | moment("LLL")
                }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn small color="warning" @click="requestParams(item)" v-if="item.request">
                  Request</v-btn
                >
                <v-btn
                  small
                  color="success"
                  v-if="item.write"
                  @click="OpenWriteData(item)"
                  >write</v-btn
                >
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="index < params.length - 1"
            :key="index"
          ></v-divider> </template
      ></v-list>
    </v-container>
    <v-dialog v-model="writeDataDialog" persistent>
      <write-canbus-values :counter="counter" :config="selected" @cancel="cancelWriteData" />
      </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import canbusValuesServices from '@/services/canbusValues.services';
import WriteCanbusValues from './WriteCanbusValues.vue';

export default {
  props: ['counter'],
  data() {
    return {
      search: '',
      writeDataDialog: false,
      selected: {},
      timeoutRefresh: '',
      intervalRefresh: '',
    };
  },
  components: {
    WriteCanbusValues,
  },
  computed: {
    ...mapGetters(['lastCanbusValues']),
    sortedParams() {
      return [...this.lastCanbusValues]
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    },
    params() {
      const tps = this.sortedParams;

      if (this.search) {
        return tps.filter((item) => item.name.toLowerCase().includes(this.search.toLowerCase()));
      }

      return tps;
    },
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    if (this.intervalRefresh) {
      clearInterval(this.intervalRefresh);
    }
  },
  watch: {
    counter() {
      this.refresh();
    },
  },
  methods: {
    ...mapActions(['getLastCanbusValues']),
    refresh() {
      if (this.intervalRefresh) {
        clearInterval(this.intervalRefresh);
      }
      this.getLastCanbusValues(this.counter);
      this.intervalRefresh = setInterval(this.refresh, 12 * 1000);
    },
    OpenWriteData(val) {
      this.selected = val;
      this.writeDataDialog = true;
    },
    cancelWriteData() {
      this.selected = {};
      this.writeDataDialog = false;
    },
    async requestParams(item) {
      await canbusValuesServices
        .requestCanbusValues({ idCounter: this.counter, idCanbusValuesConfig: item.id });
      this.timeoutRefresh = setTimeout(() => {
        this.refresh();
      }, 6000);
    },
  },
};
</script>

<style scoped>
.listconfig {
  max-height: 400px;
  overflow: auto;
}
</style>
